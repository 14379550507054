import styled from "styled-components";

export const Section = styled.section`
  padding: 3rem;
  height: auto;
  width: auto;
  color: rgb(242, 242, 242);
  background: black;

  @media only screen and (max-width: 768px) {
    .Toastify__toast-container {
      width: 180px;
    }

    .Toastify__toast-container--bottom-center, 
    .Toastify__toast-container--top-center, 
    .Toastify__toast-container--top-right {
      margin-top: 200px;
      margin-left: 4rem;
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: center; /* Centers the map horizontally */
  align-items: center;    /* Centers the map vertically */
 margin-bottom: 3rem;

  .map-container {
    position: relative;
    height: 400px;
    width: 400px;
    border: 1px solid;

    @media only screen and (max-width: 768px) {
      margin-left: -.05rem;
      margin-bottom: 2rem;
      margin-top: -2rem;
    }

    @media only screen and (max-width: 1150px) {
      margin-left: 2rem;
      margin-bottom: 2rem;
      margin-top: -2rem;
    }
  }
`;
