export const sections = [
  { title: 'Home', href: '#home' },
  { title: 'About', href: '#about' },
  { title: 'Projects', href: '#projects' },
  { title: 'Timeline', href: '#timeline' },
  { title: 'Contact', href: '#contact' },
];



export const about = {
  description: [
  'Hello! My name is Subin. I am currently pursuing my passion for technology, particularly in the field of web development. I have been immersed in learning and exploring various aspects of software development and am eager to contribute to innovative projects that cater to the dynamic needs of users.',
  'I am enthusiastic about collaborating with like-minded professionals to create exceptional websites and software solutions. My goal is to leverage my skills and knowledge gained from my education to make a meaningful impact in the tech industry.',
],
};

export const experiences = [
  {
    position: 'Front-end Web Developer Intern',
    company: 'Taubyte | Remote',
    description: [
      'Develop Taubyte websites using HTML5, CSS, Sass, JavaSript, and Vue.js.',
      'Troubleshot and debugged code ensuring a good user experience.',
      'Use Jira and Bitbucket to efficiently collaborate with other developers and track bugs.',
    ],
    time: 'April 2021 - April 2022',
  },
  {
    position: 'Material Operator',
    company: 'INVISTA | Kingston, Ontario, Canada',
    description: [
      'Contributed to the manufacturing process by operating machinery and handling materials.',
      'Maintained a safe and clean work environment by following procedures, rules, and regulations.',
      'Collaborated with team members to meet production goals and deadlines.',
    ],
    time: 'Jul 2022 - May 2023 · 11 mos',
  },
  {
    position: 'MES Systems Analyst',
    company: 'INVISTA | Kingston, Ontario, Canada',
    description: [
      'This role has split responsibilities of strong analysis, requirement definition, solving problems, and software development of Manufacturing Execution processes (including but not limited to integration with process controls).',
      'Working with a team of engineers, analysts, process controls, operations, and vendors you will analyze business process to define business requirements and apply critical thinking to solve problems and develop creative solutions which meet or exceed operations strategies.',
      'Demonstrate a high level of initiative on root cause analysis to drive improvement opportunities that enable self-serve, automation, stability, and scalability to reduce support and optimize our MES solutions. This includes on-call support for critical manufacturing systems.',
      'Apply economic thinking and communicate effectively to bring your ideas and solutions to business partners, IT teams, and leadership.',
      'Design, develop, and implement solutions for defects, enhancements, and transformation of our Manufacturing Execution Systems.',
      'Document functional/technical specifications, architecture diagrams, and/or other documentation for support and ongoing optimization.',
      'Optimize agility and performance by leveraging modern development platforms like containerization and serverless computing.',
      'Contribute to product roadmap to achieve business and strategic outcomes.',
    ],
    time: 'Jun 2023 - Present · '+calculateDuration(),
  },
 
];

export const educations = [
  {
    school: 'St. Lawrence College | Kingston, ON',
    program: 'Computer Programmer Analyst',
    time: 'April 2019 - April 2022',
    coursework: 'Relevant Coursework: MySQL, C++, Program Design, Linux, NodeJS, Web Services, C#.',
  },
  {
    school: 'Udemy, Inc | Online Learning Platform',
    program: 'Online Certification',
    course: [
      {
        name: 'Docker',
        url: 'https://www.udemy.com/certificate/UC-4dc2a8c6-c5d6-4f0c-8ac5-6bdfdf791eed/',
      },
      {
        name: 'Blazor',
        url: 'https://www.udemy.com/certificate/UC-ba63f3c1-ecb4-4820-a8b7-2782b6657810/',
      },
      {
        name: 'ASP.NET',
        url: 'https://www.udemy.com/certificate/UC-011fbe56-a1f8-4722-8e41-7f52b09014f2/',
      },
      {
        name: 'Jenkins',
        url: 'https://www.udemy.com/certificate/UC-8b08c557-b0d0-45c2-964c-b2ff1bf97f53/',
      },
    ],
  },
  {
   
    program: 'AVEVA MES',
    course: [
      {
        name: 'Operations',
       
      },
      {
        name: 'Quality',
       
      },
      {
        name: 'Performance',
       
      },
    ],
  },
];


function calculateDuration() {

  const startDate = 'Jun 2023';
  // Convert start date to Date object
  const startDateObj = new Date(startDate);

  // Get current date
  const currentDateObj = new Date();

  // Calculate duration in years and months
  const yearsDiff = currentDateObj.getFullYear() - startDateObj.getFullYear();
  const monthsDiff = currentDateObj.getMonth() - startDateObj.getMonth();
  const totalMonths = yearsDiff * 12 + monthsDiff;

  // Calculate years and months
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  // Format duration string
  let durationString = '';
  if (years > 0) {
    durationString += `${years} yr `;
  }
  if (months > 0) {
    durationString += `${months} mo`;
  }

  return durationString.trim();
}