import React from 'react';
import { Section, Title, Item, StyledLink, Link,IconWrapper } from './TimelineStyles';
import { Row, Col } from 'react-bootstrap';
import { educations, experiences } from '../../content/content';
import { BiChevronRight } from 'react-icons/bi';
import { GrCertificate  } from 'react-icons/gr';


const Education = () => {
  return (
    <Section id='timeline' data-aos='fade-up'>
      <div className='section-title'>
        <h2>Timeline</h2>
        <div className='section-line'></div>
      </div>
      <Row>
        <Col lg={6} data-aos='zoom-in'>
          <Title>Education</Title>
          {educations.map((education) => (
            <Item key={education.program}>
              <h4>{education.program}</h4>
              {education.time && <h5>{education.time}</h5>}
              <p className="company">{education.school}</p>
              <p>{education.coursework}</p>
              {education.course && (
                <ul>
                  {education.course.map((course) => (
                    <li key={course.name}>
                      <span>{course.name}</span>
                      <Link href={course.url} target='_blank' rel='noopener noreferrer' color='rgb(10, 102, 194)'>
                  <IconWrapper> <GrCertificate/></IconWrapper>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </Item>
          ))}
        </Col>
        <Col lg={6} data-aos='zoom-in'>
          <Title>Experience</Title>
          {experiences.map((job) => (
            <Item key={job.company}>
              <h4>{job.position}</h4>
              <h5>{job.time}</h5>
              <p className="company">{job.company}</p>
              <ul>
                {job.description.map((bullet) => (
                  <li key={bullet}>
                    <BiChevronRight />
                    {bullet}
                  </li>
                ))}
              </ul>
            </Item>
          ))}
        </Col>
      </Row>
    </Section>
  );
};

export default Education;
